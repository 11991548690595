.signIn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.line-container {
  position: relative;
  width: max-content;
  margin: 0 auto;
}

.line-container::before,
.line-container::after {
  content: "";
  position: absolute;
  top: 50%;
  width: 218%;
  height: 1px;
  background-color: black;
}

.line-container::before {
  right: 100%;
  margin-right: 0; /* Adjust as needed */
}

.line-container::after {
  left: 100%;
  margin-left: 0; /* Adjust as needed */
}

.word-between-lines {
  display: inline-block;
  padding: 0 10px; /* Adjust as needed */
  background-color: white; /* Optional, for better contrast */
}

/* Auth styles */

.centered-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.auth-input-container input {
  background: #fbfbfb;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 5px;
  padding-left: 45px;
  padding-top: 25px;
  height: 57px;
}

.auth-input-container img {
  margin-bottom: -37px;
  margin-left: 12px;
  position: relative;
  z-index: 9;
}

.label {
  margin-bottom: -20px;
  margin-left: 12px;
  position: relative;
  z-index: 30;
  font-weight: 500;
  font-size: 10px;
  color: var(--sixth);
  margin-left: 45px;
}
.auth-input-icon {
  position: relative;
  top: 22px;
  left: 10px;
}
.bottom-text-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
}

.error-msg {
  color: red;
  font-size: 11px;
}

.forgot-txt {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  text-decoration: underline;
  color: var(--secondary);
  cursor: pointer;
}

.sign-up-text {
  font-size: 18px;
  line-height: 21px;
  color: var(--secondary);
  margin-top: 70px;
  font-family: "Roboto";
  font-weight: 500;
}

.sign-up-text span {
  color: var(--primary);
  text-decoration: underline;
  cursor: pointer;
}

.error-icn {
  margin-bottom: 0px !important;
}

.branch-icon {
  position: relative;
  top: 36px;
  left: 12px;
  z-index: 1;
  font-size: 19px;
}

@media only screen and (min-width: 600px) {
  /* .auth-input-container {
      width: 382px;
    } */

  .bottom-text-container {
    display: flex;
  }
}

/* End auth styles */
