* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  background-color: #ffffff;
}

::-webkit-scrollbar {
  background-color: #ebecf1;
  width: 0.4vw;
}

::-webkit-scrollbar-thumb {
  background: black;
}

.loader-container {
  position: relative;
  margin-top: 20px;
}

.dots-bars-5 {
  width: 40px;
  height: 30px;
  --c: linear-gradient(#5550bd 0 0);
  background: var(--c) 0 100%/8px 30px, var(--c) 50% 100%/8px 20px,
    var(--c) 100% 100%/8px 10px;
  background-repeat: no-repeat;
  position: relative;
  clip-path: inset(-100% 0);
}

.dots-bars-5:before {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #5550bd;
  left: -16px;
  top: 0;
  animation: db5-1 2s linear infinite,
    db5-2 0.5s cubic-bezier(0, 200, 0.8, 200) infinite;
}

@keyframes db5-1 {
  0% {
    left: -16px;
    transform: translateY(-8px);
  }

  100% {
    left: calc(100% + 8px);
    transform: translateY(22px);
  }
}

@keyframes db5-2 {
  100% {
    top: -0.1px;
  }
}

.loader-wrapper {
  display: flex;
  height: 100vh;
  width: 100%;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter-btn {
  padding: 0.5rem 0.8rem;
  cursor: pointer;
  border-radius: 0.3rem;
}

.filter-btn:hover {
  background-color: #5550bd;
  color: #ebecf1;
}
.root-link {
  text-decoration: none;
  color: #495057 !important;
}

.root-link:hover {
  background: #5550bd !important;
  cursor: pointer;
  color: white !important;
}

.subnav-children {
  display: flex;
  flex-direction: column;
  margin-left: 40px;
}

.agent-stat-title {
  font-size: 15px !important;
}

.salestable {
  margin-top: 1.4rem;
  margin-right: 2px;
  padding: 10px;
  -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  overflow-x: scroll;
}

table {
  margin-top: 1rem;
  width: 100%;
  border-spacing: 0;
}

tr td:last-child {
  border-bottom: 150;
  border-top: 250;
  color: black;
  font-weight: 450;
  font-size: 15px;
}

th {
  text-align: right;
  padding: 0.2rem 0.2rem;
}
th:first-child {
  text-align: left;
}

td {
  margin: 0;
  color: #5550bd;
  padding: 0.5rem 0.2rem;
  text-align: left;
  border-bottom: 1px solid gray;
  font-size: 13px;
}

td:first-child {
  font-weight: 450;
}
td:last-child {
  border-right: 0;
}

td {
  text-align: center;
}
.text-right {
  text-align: right !important;
}
tr,
td:first-child {
  text-align: left;
}

.agent-mapping {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
